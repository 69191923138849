
// Converted Variables
@import 'variables';

// Custom Media Query Variables

.js_enabled #cookie-message {
    display: none;
}
#cookie-message {
    background-color: #be9c6b;
    text-align: center;
    margin: 0 auto;
    padding: 1em;
}
#cookie-message p {
  margin: 0;
}

body {
  color: #4f2c1e;
  padding: 90px 0 0 0;
  -webkit-transition: all;
  -moz-transition: all;
  -ms-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 12px;
  color: #4f2c1e;
  overflow-x: hidden !important;
}
hr {
  border-color: #c8b59e;
  margin: 10px 0 30px;
}
h1 {
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 60px;
  color: #4f2c1e;
}
h2 {
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 32px;
  color: #4f2c1e;
}
h2.pink {
  color: #ed19d7;
}
h2.pink2 {
  color: #af538a;
}
h2.orange {
  color: #ff5a12;
}
h2.purple {
  color: #513557;
}
h2.newpurple {
  color: #4f2188;
}
h2.purple2 {
  color: #775ca7;
}
h2.gold {
  color: #bc9862;
}
h3 {
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 28px;
  color: #4f2c1e;
}
h3.border {
  position: relative;
  font-size: 40px;
  padding-left: 220px;
}
h3.border:after {
  position: absolute;
  content: ' ';
  width: 196px;
  height: 3px;
  background: #912800;
  left: 0;
  top: 30px;
}
h3.gold {
  width: 518px;
  font-size: 31px;
  color: #99803d;
  margin-bottom: 70px;
}
h3.gold:after {
  position: absolute;
  content: ' ';
  width: 196px;
  height: 3px;
  background: #912800;
  left: 0;
  top: 20px;
}
h3.red {
  font-size: 40px;
  color: #4e2413;
  margin-top: 50px;
}
h3.red:after {
  top: 15px;
}
h3.purple {
  font-size: 30px;
  color: #3c0f74;
  margin-top: 50px;
}
h3.purple:after {
  position: absolute;
  content: ' ';
  width: 196px;
  height: 3px;
  background: #912800;
  left: 0;
  top: 20px;
}
h3.purple-2 {
  font-size: 30px;
  color: #513593;
  margin-top: 50px;
}
h3.purple-2:after {
  position: absolute;
  content: ' ';
  width: 196px;
  height: 3px;
  background: #513593;
  left: 0;
  top: 20px;
}
h3.gold-left {
  width: 511px;
  font-size: 31px;
  color: #99803d;
  padding: 0 200px 0 0;
  position: relative;
  margin-bottom: 70px;
}
h3.gold-left:after {
  position: absolute;
  content: ' ';
  width: 196px;
  height: 3px;
  background: #912800;
  top: 20px;
  right: 0;
  left: auto;
}
h3.gold-big {
  width: 635px;
  font-size: 31px;
  color: #99803d;
  margin-bottom: 70px;
}
h3.gold-big:after {
  position: absolute;
  content: ' ';
  width: 196px;
  height: 3px;
  background: #912800;
  left: 0;
  top: 10px;
}
h4 {
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 20px;
  color: #4f2c1e;
}
h4.margin {
  margin-left: 220px;
  margin-bottom: 30px;
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 30px;
  color: #ad906e;
}
h5 {
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 18px;
  color: #4f2c1e;
}
h6 {
  font-family: 'martelheavy';
  font-weight: normal;
  font-size: 12px;
  color: #4f2c1e;
}
p {
  line-height: 18px;
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 12px;
  color: #4f2c1e;
}
form {
  background: #f1e8e0;
}
form .form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
form label {
  line-height: 26px;
  margin-bottom: 0;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 14px;
  color: #85685b;
}
form label.control-label {
  display: block;
}
form label.control-label.big {
  font-size: 20px;
}
form label.radio-inline,
form label.checkbox-inline {
  margin-top: 8px !important;
  font-size: 12px;
}
form label b {
  color: #4f2c1e;
}
form input[type="text"],
form input[type="email"],
form input[type="password"],
form input[type="number"],
form select {
  width: 100%;
  height: 40px;
  line-height: 20px;
  padding: 12px 10px 8px;
  outline: 0;
  border: 0;
  background: #fff;
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 12px;
  color: #4f2c1e;
}
form textarea {
  line-height: 20px;
  padding: 12px 10px 8px;
  border: 0;
  background: #fff;
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 12px;
  color: #4f2c1e;
}
form textarea.form-control {
  min-height: 100px;
  line-height: 20px;
  padding: 12px 10px 8px;
  border: 0;
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 12px;
  color: #4f2c1e;
}
form input[type="radio"] {
  width: 14px;
  height: 14px;
  background: transparent;
  position: relative;
}
form input[type="radio"]:after {
  width: 16px;
  height: 16px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: #fff;
  content: '';
  top: -1px;
  left: -1px;
  position: absolute;
}
form input[type="radio"]:checked:after {
  background: #85685b;
}
form input[type="checkbox"] {
  width: 14px;
  height: 14px;
  background: transparent;
  position: relative;
}
form input[type="checkbox"]:after {
  width: 16px;
  height: 16px;
  background: #fff;
  content: '';
  top: -1px;
  left: -1px;
  position: absolute;
}
form input[type="checkbox"]:checked:after {
  line-height: 16px;
  content: '✔';
  text-align: center;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 10px;
  color: #85685b;
}
form p.help-block {
  line-height: 15px;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 11px;
  color: #4f2c1e;
}
form a.help-block {
  margin: 10px 0 0;
  line-height: 15px;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 12px;
  color: #775ca7;
  text-decoration: underline;
}
form .has-error label {
  color: #ae1f6f;
}
form .has-error input[type="text"],
form .has-error input[type="email"],
form .has-error input[type="password"],
form .has-error input[type="number"],
form .has-error select,
form .has-error textarea {
  border: #ae1f6f 2px solid !important;
}

.btn-border {
  display: inline-block;
  background-color: #fff;
  border: 1px solid #979797;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 31px;
  color: #4c2c1e !important;
  text-decoration: none !important;
}
.btn-border:link,
.btn-border:hover {
  color: #979797 !important;
}
.bg-danger {
  padding: 12px 10px 8px;
  border: #ae1f6f 2px solid;
  text-align: center;
  background: none;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 14px;
  color: #ae1f6f;
}
.btn-link {
  line-height: 38px;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 12px;
  color: #775ca7;
  text-transform: uppercase;
  text-decoration: underline;
}
.btn-link:hover {
  color: #775ca7;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-link.black {
  font-size: 10px;
  color: #4f2c1e;
}
.btn-link.black:hover {
  color: #4f2c1e;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn {
  line-height: 38px;
  height: 38px;
  padding: 0 20px;
  border: #9b7f81 2px solid;
  background: #fff;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 12px;
  color: #775ca7;
  text-transform: uppercase;
  cursor: pointer;
}
.btn.btn-default {
  background: #fff;
  color: #775ca7;
  border-color: #9b7f81;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn.btn-default:hover {
  background: #ad906e !important;
  color: #fff;
  border-color: #ad906e !important;
}
.btn.btn-primary {
  background: #775ca7;
  color: #fff;
  border-color: #9b7f81;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn.btn-primary:hover {
  background: #ad906e !important;
  color: #fff;
  border-color: #ad906e !important;
}

.navbar {
  height: 90px;
  margin: 0;
  z-index: 100000;
  -webkit-transition: all;
  -moz-transition: all;
  -ms-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
}
.navbar.navbar-default {
  background: #fff;
  border-top: 0;
  border-bottom: 5px solid #ae1f6f;
}
.navbar.navbar-default:after {
  position: absolute;
  content: ' ';
  height: 5px;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #513593;
}
.navbar .logo {
  height: 52px;
  margin: 11px 0 18px 0px;
  float: left;
  position: relative;
}
.navbar .right {
  width: 205px;
  padding-top: 26px;
  margin: 10px 0px 0 0;
  float: right;
  position: relative;
}
.navbar.single .right {
  width: 265px;
  padding-top: 6px;
}
.navbar .right .nav {
  top: 0;
  right: 0;
  position: absolute;
}
.navbar .right .nav:after {
  width: 26px;
  height: 1px;
  background: #000;
  content: '';
  top: 8px;
  left: -40px;
  position: absolute;
}
.navbar .right .nav li a {
  padding: 0;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 11px;
  color: #9b9b9b;
}
.navbar .right .search {
  float: left;
  position: relative;
}
.navbar .right .search .icon {
  width: 22px;
  height: 29px;
  background: url("../img/header-sprite.png");
  float: left;
}
.navbar .right .search .icon:hover,
.navbar .right .search .icon.active {
  background-position: bottom left;
}
.navbar .right .search .dropdown-menu {
  width: 129px;
  min-width: 129px;
  height: 38px;
  padding: 0;
  margin: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: 0;
  top: -2px;
  left: -98px;
}
.navbar .right .search .dropdown-menu input {
  padding-right: 35px;
  border: #887661 1px solid;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #000;
}
.navbar .right .search .dropdown-menu .icon {
  top: 2px;
  right: 8px;
  position: absolute;
}
.navbar .right .safe {
  width: 143px;
  height: 50px;
  line-height: 15px;
  padding: 10px 20px 10px 35px;
  border-right: #ad906e 1px solid;
  background: url("../img/safe-locked.png") left 7px no-repeat;
  font-size: 12px;
  color: #000;
  float: left;
}
.navbar .right .tel {
  margin: 4px 20px 0;
  float: left;
}
.navbar.single .tel {
  margin-top: 10px;
  float: right;
}
.navbar .right .tel p {
  line-height: 14px;
  margin: 0;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 12px;
  color: #9d9d9d;
}
.navbar .right .tel p + p {
  color: #000;
}
.navbar .right .fav {
  width: 22px;
  height: 29px;
  background: url("../img/header-sprite.png") top center;
  float: left;
}
.navbar .right .fav:hover {
  background-position: bottom center;
}
.navbar .right .bag {
  margin: 2px 0 0 19px;
  float: left;
  position: relative;
  z-index: 1031;
}
.navbar .right .bag .icon {
  width: 22px;
  height: 29px;
  background: url("../img/header-sprite.png") top right;
  display: block;
}
.navbar .right .bag .icon:hover {
  background-position: bottom right;
}
.navbar .right .bag .count {
  width: 25px;
  height: 25px;
  line-height: 27px;
  background: #ed19d7;
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  border-radius: 13px;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 12px;
  color: #fff;
  text-align: center;
  top: 14px;
  left: 10px;
  position: absolute;
}
#nav {
  max-width: 768px;
  top: 5px;
  left: 50%;
  position: absolute;
  z-index: 1000;
  -moz-transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);

  .form-busca {
    background: $white;
    input.busca {
      padding: 6px 12px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      border: 1px solid #ccc;
    }

    .input-group-addon {
      button {
        width: 22px;
        height: 22px;
        background: url(../img/header-sprite.png);
        background-position-y: -5px;
        border: 0;
      }
    }
  }
}
// #nav:before {
//   width: 77px;
//   height: 1px;
//   background: #bfbfbf;
//   top: 8px;
//   left: -85px;
//   position: absolute;
// }
// #nav:after {
//   width: 77px;
//   height: 1px;
//   background: #bfbfbf;
//   top: 8px;
//   right: -85px;
//   position: absolute;
// }
#nav li {
  position: relative;
}
#nav li:before {
  width: 1px;
  height: 1px;
  background: #898989;
  content: '';
  bottom: 8px;
  left: 0;
  position: absolute;
}
#nav li a {
  padding: 0 5px;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 10px;
  color: #898989;
  text-transform: uppercase;
}
#nav li a:hover,
#nav li a.active {
  color: #d7ad5f;
}
#menu {
  width: 100%;
  margin-top: -25px;
  text-align: center;
}
#menu > li {
  display: inline-block;
  vertical-align: top;
  float: none;
}
#menu > li > a {
  padding: 0 7px 13px;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 14px;
  color: #000;
  position: relative;
  z-index: 1031;
}
#menu > li:hover > a,
#menu > li > a.active {
  color: #d7ad5f;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
#menu > li:hover > a:after,
#menu > li > a.active:after {
  width: 100%;
  height: 0;
  border-bottom: #d7ad5f 2px solid;
  content: '';
  top: 100%;
  left: 0;
  position: absolute;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
#menu > li .submenu {
  width: 100%;
  max-height: 0;
  background: #fefcfb;
  text-align: left;
  top: 80px;
  left: 0;
  position: fixed;
  z-index: 1030;
  overflow: hidden;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
#menu > li:hover .submenu {
  max-height: 300px;
}
#menu > li .submenu .center {
  width: 680px;
  padding: 26px 0 20px;
  margin: auto;
  overflow: hidden;
}
#menu > li .submenu .center p.title {
  margin: 0 20px 0 0;
  width: 170px;
  min-height: 1px;
  line-height: 24px;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 15px;
  color: #ad906e;
  float: left;
}
#menu > li .submenu.chocolate p.title,
#menu > li .submenu.sabores p.title,
#menu > li .submenu.especiais p.title {
  color: #af538a;
}
#menu > li .submenu.chocolate p.title a,
#menu > li .submenu.sabores p.title a,
#menu > li .submenu.especiais p.title a {
  color: #af538a !important;
  text-decoration: none;
}
#menu > li .submenu.linhas p.title {
  line-height: 18px;
  font-size: 14px;
  color: #775ca7;
}
#menu > li .submenu.linhas p.title a {
  margin-top: 15px;
  font-size: 12px;
  color: #c891b0;
  display: block;
  text-decoration: underline;
}
#menu > li .submenu .center .item {
  width: 25%;
  float: left;
  margin: auto;
  padding-left: 20px;
  padding-right: 35px;
  min-height: 130px;
  border-right: 1px solid rgba(151,151,151,0.46);
}
#menu > li .submenu .center .item:last-child {
  border-right: none;
}
#menu > li .submenu .center .item p {
  line-height: 24px;
  margin: 0;
  font-family: 'Libre Baskerville', serif;
  font-weight: normal;
  font-size: 14px;
  color: #7e3e32;
  text-transform: uppercase;
}
#menu > li .submenu .center .item p a {
  color: #7e3e32;
}
#menu > li .submenu .center .item ul {
  padding: 0;
  list-style: none;
}
#menu > li .submenu .center .item ul li {
  text-align: left;
}
#menu > li .submenu .center .item ul li a {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  line-height: 24px;
  font-family: 'Libre Baskerville', serif;
  font-weight: normal;
  font-size: 12px;
  color: #7e3e32;
  text-decoration: none;
}
#menu > li .submenu .center .item ul li a:hover {
  color: #d7ad5f;
}
#menu > li .submenu.chocolate .item {
  width: 33.33%;
  padding-left: 60px;
  padding-right: 0;
}
#menu > li .submenu.chocolate .item ul li {
  width: 100%;
}
#menu > li .submenu.chocolate .item ul li strong {
  font-family: 'martelheavy';
}
#menu > li .submenu.linhas .item {
  padding-right: 25px;
}
.navbar.checkout .container {
  width: 100%;
}
.navbar.checkout .steps {
  width: 687px;
  margin: 0 auto;
  display: block;
  float: none;
  padding-top: 15px;
}
.navbar.checkout .steps a {
  cursor: default;
}
.navbar.checkout .right {
  padding-top: 0;
  margin-top: 0;
}

#logedIn .nav:after {
  left: -25px;
}
#logedIn .logged {
  width: 110px;
}
#logedIn .logged a {
  color: #ed19d7;
  margin: 3px 5px;
  display: block;
  text-align: center;
}
#logedIn .logged.active .dropdown-menu {
  display: block;
}
#logedIn .open > a {
  background: none;
}
#logedIn .user-menu {
  min-width: auto;
  background: #ae1f6f;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 0 10px;
  top: 25px;
  left: -5px;
  position: absolute;
  z-index: 1150;
}
#logedIn .user-menu:before {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ae1f6f;
  position: absolute;
  top: -10px;
  left: 50px;
}
#logedIn .user-menu li {
  padding: 10px 0;
}
#logedIn .user-menu li a {
  color: #fff;
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 11px;
  color: #fff;
}
#logedIn .user-menu li a:hover {
  background: #ae1f6f;
}
#logedIn .user-menu li:nth-child(2) {
  border-bottom: 1px solid #fff;
}
.nav-tabs {
  padding: 0 10px;
  border-color: #c8b59e;
  font-size: 0;
  text-align: center;
}
.nav-tabs > li {
  display: inline-block;
  vertical-align: bottom;
  float: none;
}
#loja-online {
  .nav-pills  {
    & > li {
      float: none;
    }
  }
}

.nav-tabs li {
  padding: 0;
}
.nav-tabs li a {
  height: 40px;
  line-height: 42px;
  padding-top: 0;
  padding-bottom: 0;
  border: #c8b59e 1px solid;
  margin: 0 0 0 -1px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 14px;
  color: #c8b59e;
  text-align: center;
  text-transform: uppercase;
  background: none;
}
.nav-tabs li a:hover {
  border: #c8b59e 1px solid;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  background: none;
}
.nav-tabs li.active a {
  border: #c8b59e 1px solid;
  background: #c8b59e;
  color: #fff;
}
.nav-tabs li.active a:hover,
.nav-tabs li.active a:focus {
  border: #c8b59e 1px solid;
  background: #c8b59e;
  color: #fff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.modal-backdrop.in {
  opacity: 0.7;
}
.modal .modal-dialog {
  width: 540px;
  margin: 0;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute;
}
.modal .modal-dialog.big {
  width: 820px;
}
.modal.in .modal-dialog,
.modal.fade .modal-dialog {
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.modal .modal-content {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: 0;
  background: #f1e8e0;
}
.modal .modal-content .modal-header {
  height: 40px;
  line-height: 44px;
  padding: 0;
  border: 0;
  background: #c7b299;
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 18px;
  color: #4f2c1e;
  text-align: center;
  text-transform: uppercase;
}
.modal .modal-content .modal-header .close {
  width: 20px;
  height: 20px;
  top: 12px;
  right: 10px;
  position: absolute;
  opacity: 1;
}
.modal .modal-content .modal-header .close:before {
  width: 28px;
  height: 28px;
  border-right: #4f2c1e 1px solid;
  content: '';
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -14px;
  left: -14px;
  position: absolute;
}
.modal .modal-content .modal-header .close:after {
  width: 28px;
  height: 28px;
  border-right: #4f2c1e 1px solid;
  content: '';
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 5px;
  left: -13px;
  position: absolute;
}
.modal .modal-content .modal-header .close:focus {
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.container {
  width: 1260px;
}
.container.small {
  width: 1076px;
  padding: 10px 0 0 0;
  margin: auto;
}
.small-content {
  max-width: 800px;
  margin: 0 auto;
}
.img-header img {
  max-width: 100%;
  height: auto;
}

.central-title {
  margin: 40px auto 40px auto;
}
.central-title h2 {
  text-align: center;
}
.central-title h2 span {
  margin: auto;
  position: relative;
  display: inline-block;
  line-height: 36px;
}
.central-title h2 span:after {
  position: absolute;
  content: ' ';
  width: 78px;
  height: 1px;
  background: #d1d1d1;
  top: 15px;
  left: -120px;
}
.central-title h2 span:before {
  position: absolute;
  content: ' ';
  width: 78px;
  height: 1px;
  background: #d1d1d1;
  top: 15px;
  right: -120px;
}
.central-title .description {
  text-align: center;
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 15px;
  color: #4f2188;
  padding: 0 50px;
}

.relative {
  position: relative;
}

.glyphicon-user:before {
  content: "\e008" !important;
}

input:focus::-webkit-input-placeholder {
  color: transparent !important;
}
input:focus::-moz-placeholder {
  color: transparent !important;
}
input:focus::-ms-input-placeholder {
  color: transparent !important;
}
input:focus::-o-input-placeholder {
  color: transparent !important;
}

.through {
  font-size: 10px !important;
  text-decoration: line-through;
  margin-top: -20px;
}

.label-error-message {
  text-align: center;
  padding: 25px 0 0;
  font-size: 12px;
  height: 43px;
  clear: both;
  line-height: 16px;
  overflow: hidden;
  color: #ab1f6e;
}
.label-error-message label {
  display: block;
  line-height: 24px;
  font-size: 12px;
  color: #ab1f6e !important;
}
form .has-error label,
.label-error-message {
  color: #ae1f6f !important;
}

.txt-smlr {
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
}
.txt-smlr .pink {
  color: #ad1d71;
}
/************* FOOTER ***********/

.border-dual-color {
  width: 100%;
  border-bottom: 5px solid #513593 !important;
  border-top: 5px solid #ae1f6f !important;
  height: 10px;
}

#footer {
  border-bottom: 1px solid #888;
  padding: 30px 0;
}
#footer .menu-footer {
  padding: 0;
  margin: 0;
}
#footer .menu-footer li {
  list-style: none;
  position: relative;
  margin: 15px 0;
}
#footer .menu-footer li:after {
  position: absolute;
  content: ' ';
  width: 10px;
  height: 1px;
  background: #4f2c1e;
  bottom: 3px;
  left: 0;
}
#footer .menu-footer li a {
  display: block;
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 12px;
  color: #4f2c1e;
  line-height: 12px;
  padding: 0 0 0 15px;
  text-decoration: none;
}
#footer .menu-footer li a:hover {
  text-decoration: underline;
}
#footer .formas-pagamento {
  border-top: 0px !important;
}
#footer .formas-pagamento h5 {
  margin-bottom: 21px;
}
#footer .formas-pagamento p {
  width: 300px;
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 12px;
  color: #000;
  margin-bottom: 21px;
}
#footer .formas-pagamento ul {
  padding: 0;
  margin: 0;
}
#footer .formas-pagamento ul li {
  width: 56px;
  height: 26px;
  list-style: none;
  display: inline-block;
  vertical-align: top;
  background: url("../img/../img/pagamentos-sprite.png");
}
#footer .formas-pagamento ul li:first-child {
  margin: 0 6px 0 0;
}
#footer .formas-pagamento ul li.visa {
  background-position: -56px 0;
}
#footer .formas-pagamento ul li.american-express {
  background-position: -112px 0;
}
#footer .formas-pagamento ul li.boleto {
  background-position: -168px 0;
}
#footer .formas-pagamento ul li.onebuy {
  background-position: -224px 0;
}

#footer .formas-pagamento img {
  width: 65px;
  margin: 0 10px 0 10px;
}

#footer .entrega {
  margin: 0;
}
#footer .entrega ul {
  padding: 0;
  margin: 0;
}
#footer .entrega ul li {
  list-style: none;
  margin: 16px 0;
}
#footer .entrega.large ul li {
  width: 33.3333%;
  float: left;
}
#footer .entrega ul li h6 {
  margin: auto auto 4px auto;
}
#footer .entrega ul li .img {
  width: 40px;
  height: auto;
  margin: auto 10px auto auto;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}
#footer .entrega.large ul li .img {
  vertical-align: top;
}
#footer .entrega ul li div {
  width: 200px;
  display: inline-block;
  vertical-align: middle;
}
#footer .entrega ul li div .text {
  width: 190px;
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 12px;
  color: #4f2c1e;
  line-height: 14px;
}
#footer .novidades .form-footer {
  background: #fff;
  padding-bottom: 82px;
  border-bottom: 1px solid #888;
}
#footer .novidades .form-footer input {
  background: #f5f3f0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
#footer .novidades .form-footer button {
  background: #fff;
  margin: 18px 0 0 0;
  float: right;
  border: 0;
  padding: 7px 5px 5px 5px;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 12px;
  color: #d7ad5f;
  line-height: 14px;
  text-transform: uppercase;
  outline: none;
}
#footer .novidades .form-footer button:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
#footer .novidades .redes-sociais {
  margin-top: 45px;
}
#footer .novidades .redes-sociais ul {
  padding: 0;
  margin: 25px 0 0 0;
  text-align: center;
  font-size: 0;
}
#footer .novidades .redes-sociais ul li {
  width: 36px;
  height: 22px;
  margin: 0 9px;
  list-style: none;
  display: inline-block;
  vertical-align: top;
}
#footer .novidades .redes-sociais ul li:first-child {
  margin: 0 9px 0 0;
}
#footer .novidades .redes-sociais ul li a {
  display: block;
  width: 36px;
  height: 22px;
  background: url("../img/../img/redes-sociais-sprite.png");
}
#footer .novidades .redes-sociais ul li a.twitter {
  background-position: -36px 0;
}
#footer .novidades .redes-sociais ul li a.instagram {
  background-position: -72px 0;
}
#footer .novidades .redes-sociais ul li a.youtube {
  background-position: -108px 0;
}
#footer .novidades .redes-sociais ul li a.tripadvisor {
  background-position: -144px 0;
}
#footer .novidades .redes-sociais ul li a:hover {
  background-position-y: bottom;
}

@keyframes pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
}

@-moz-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
}

#footer .whatsapp-button, .pulse-button {
  position: fixed;
  bottom: 2%;
  right: 3%;
  width: 215px;
  z-index: 1020;
  height: 45px;
  border: none;
  box-shadow: 0 0 0 0 #00d8563b;
  border-radius: 100px;
  padding: 2px 6px;
  background-color: #25D366;
  border: 1px solid #25D366;
  cursor: pointer;
  -webkit-animation: pulse 1.45s infinite cubic-bezier(0.44, 0, 0, 1);
  -moz-animation: pulse 1.45s infinite cubic-bezier(0.44, 0, 0, 1);
  -ms-animation: pulse 1.45s infinite cubic-bezier(0.44, 0, 0, 1);
  animation: pulse 1.85s infinite cubic-bezier(0.66, 0, 0, 0.8);
  font-family: 'Roboto','Helvetica Neue',Helvetica,Arial,sans-serif;
}

.pulse-button::before {
  content: 'Fale pelo WhatsApp';
  position: absolute;
  left: 85px;
  top: -13px;
  font-size: 1.2em;
  padding: 8px 12px;
  opacity: 1;
  color: #fff;
  pointer-events: none;
  white-space: nowrap;
  border-radius: 5px;
  transition: all .2s ease;
  transform: translate3d(-40%, 10px, 0) scale(.9);
}

.pulse-button::after {
  content: 'ou pelo telefone (11) 3168-2720';
  position: absolute;
  left: 100px;
  top: 4px;
  font-size: 1.2em;
  padding: 8px 12px;
  opacity: 1;
  color: #fff;
  pointer-events: none;
  white-space: nowrap;
  border-radius: 5px;
  transition: all .2s ease;
  transform: translate3d(-40%, 10px, 0) scale(.9);
}

.pulse-button i {
  font-size: 2vh;
  line-height: 40px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  text-shadow: 0 3px 4px #287b4700;
  transition: .59s;
  transform: scale(1);
}

.rodape {
  text-align: center;
  margin: 25px 0;
}
.rodape p {
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 12px;
  color: #888;
}
